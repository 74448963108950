import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words",
  "data-test-modal-headline": ""
}
const _hoisted_2 = {
  key: 1,
  class: "flex justify-end px-4 pb-4"
}

import { ref, onBeforeUnmount } from "vue";
import CloseButton from "../CloseButton/CloseButton.vue";
import SharedButton from "../SharedButton.vue";
import { animationDurationMs } from "../../../services/services.modal";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    wide: { type: Boolean },
    fullMaxHeight: { type: Boolean },
    narrow: { type: Boolean },
    closeActionButton: { type: Boolean },
    closeActionButtonText: { default: "Ok" },
    headline: {},
    noScrollbar: { type: Boolean },
    noBodyPadding: { type: Boolean },
    zIndex: {}
  },
  emits: ["closing", "closed"],
  setup(__props: any, { expose: __expose, emit: __emit }) {


const emit = __emit;

const slideIn = ref(true);

const timeout = ref<number>();
onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});

const close = (triggeredBy = "") => {
	slideIn.value = false;
	emit("closing", triggeredBy);
	timeout.value = window.setTimeout(() => {
		emit("closed");
	}, animationDurationMs);
};
__expose({ close });

return (_ctx: any,_cache: any) => {
  const _component_MountingPortal = _resolveComponent("MountingPortal")!

  return (_openBlock(), _createBlock(_component_MountingPortal, {
    "mount-to": "body",
    append: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["tw fallback-modal modalOverlay animated fixed left-0 top-0 h-full w-screen items-end justify-center lg:items-center", slideIn.value ? 'modalFadeIn' : 'modalFadeOut']),
        style: _normalizeStyle({ '--animationDuration': `${_unref(animationDurationMs)}ms`, '--z-index': _ctx.zIndex }),
        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (close('overlay')), ["self","stop"]))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal animated relative flex w-full flex-col bg-white text-black-primary", [
					slideIn.value ? 'modalSlideInUp' : 'modalSlideOutDown',
					{
						'is-narrow': _ctx.narrow,
						'is-wide': _ctx.wide,
						'max-h-full': _ctx.fullMaxHeight,
					},
				]]),
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(CloseButton, {
            class: "right-4 top-4",
            "data-test-modal-close-button": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (close('closeButton')))
          }),
          (_ctx.headline)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.headline), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["pb-4", [
						_ctx.noScrollbar ? 'flex flex-col overflow-y-hidden' : 'o-scrollbar overscroll-contain',
						{
							'lg-down:pb-9': !_ctx.closeActionButton,
							'px-4 pt-6': !_ctx.noBodyPadding,
						},
					]])
          }, [
            (_ctx.$slots.headerblock)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["-mt-5 bg-gray-20 px-4 py-9", { '-mx-4': !_ctx.noBodyPadding }])
                }, [
                  _renderSlot(_ctx.$slots, "headerblock")
                ], 2))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ], 2),
          (_ctx.closeActionButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(SharedButton, {
                  class: "min-w-25",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (close('actionButton')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.closeActionButtonText), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 6)
    ]),
    _: 3
  }))
}
}

})