<template>
	<div>
		<dt>
			<button
				class="relative flex w-full select-none justify-between border-b border-gray-30 p-4 text-left text-sm outline-none before:absolute before:-left-[2px] before:-top-[2px] before:z-10 before:h-[calc(100%_+_4px)] before:w-[calc(100%_+_4px)] before:rounded before:border-2 before:border-transparent focus-visible:before:border-black-primary"
				:class="{
					'bg-gray-30': isExpanded,
					'cursor-not-allowed text-gray-50': disabled,
					'text-black-primary supportsHover:hover:bg-gray-30': !disabled,
				}"
				:disabled="disabled"
				style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"
				@click="handleClick"
			>
				{{ title }}
				<SvgIcon class="ml-4 h-5 w-5 shrink-0" :icon-name="isExpanded ? 'minus-thin' : 'plus-thin'" />
			</button>
		</dt>
		<TransitionExpand>
			<dd v-show="isExpanded" class="border-b border-gray-30 text-sm" :aria-hidden="isExpanded ? 'false' : 'true'">
				<div class="p-4">
					<slot />
				</div>
			</dd>
		</TransitionExpand>
	</div>
</template>

<script setup lang="ts">
import { inject, onMounted, ref, watch } from "vue";
import SvgIcon from "../SvgIcon/SvgIcon.vue";
import TransitionExpand from "./TransitionExpand.vue";

const props = defineProps<{
	disabled?: boolean;
	isExpanded?: boolean;
	title: string;
}>();

const isExpanded = ref();
const isExpanding = ref(false);
const emit = defineEmits(["header-clicked"]);

onMounted(() => {
	isExpanded.value = props.isExpanded;
});

const handleClick = () => {
	emit("header-clicked");

	if (isExpanded.value) {
		isExpanded.value = false;
		return;
	}
	isExpanded.value = true;
	isExpanding.value = true;
	collapseOthers();
};

const collapseOthers = inject("collapseOthers", () => {});
const isCollapsing = inject("isCollapsing", ref(false));
watch(
	() => isCollapsing.value,
	(newVal) => {
		if (!newVal) return;
		isExpanded.value = isExpanding.value;
		isExpanding.value = false;
	}
);
</script>
